.buttonLeft {
    display: flex;
    padding-bottom: 5px;
    margin-bottom: 20px; 
    border-bottom: 1px solid var(--color-dark-5);
}

.buttonLeft p {
    margin: 0px;
    cursor: pointer;
    user-select: none;
    color: var(--color-font-light);
}

.buttonLeft:hover {
    border-left:3px solid var(--color-blue-2);
    padding-left: 10px;
}

.buttonLeft.selected {
    border-left:3px solid var(--color-blue-2);
    padding-left: 10px;
}