.container_searchinput {
    display: flex;
    flex-direction: column;
    align-items: left;
    /*padding-bottom: 40px;*/
}

.container_searchinput ul {
    margin: 0;
    padding: 0;
}

.container_searchinput li {
    list-style: none;
}

.container_searchinput .input::placeholder {
    color: #b3b3b3;
}

.dropdownSearch {
    position: absolute;
    width: 100%;
    min-width: 0;
    max-width: 430px;
    height: 100%;
    max-height: 250px;
    overflow-x: hidden;
    border: 1px solid #b3b3b3;
    background-color: #fff;
    visibility: hidden;
    z-index: 2;
    margin-top: 35px;
    background-color: var(--color-light-100);
    border: 1px solid var(--color-dark-20);  
}

.dropdownSearch_visible {
    position: absolute;
    width: 100%;
    min-width: 0;
    max-width: 430px;
    height: 100%;
    max-height: 250px;
    overflow-x: hidden;
    border: 1px solid #b3b3b3;
    background-color: #fff;
    visibility: visible;
    z-index: 10;
    margin-top: 35px;
    background-color: var(--color-light-100);
    border: 1px solid var(--color-dark-20);
}
.dropdown_item {
    width: 100%;
    max-width: 407px;
    /*padding: 12px;*/
    cursor: pointer;

    &:hover {
        background-color: var(--color-dark-10);
    }

    border-bottom: 1px solid var(--primary-border-color);
    padding-left: 12px;
    padding-right: 12px;
}

.item_text1 {
    color: var(--primary-font-color);
    margin-bottom: 5px;
    font-size: 18px;
}

.item_text2 {
    font-size: 14px;
    color: var(--terciary-font-color);
}