@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --color-blue-1:rgb(0, 39, 66);
  --color-blue-2:rgb(39, 197, 216);
  --color-blue-3:rgb(162, 221, 237);

  --color-dark-100: rgba(0,0,0,1);
  --color-dark-90: rgba(0,0,0,0.9);
  --color-dark-80: rgba(0,0,0,0.8);
  --color-dark-70: rgba(0,0,0,0.7);
  --color-dark-50: rgba(0,0,0,0.5);
  --color-dark-30: rgba(0,0,0,0.3);
  --color-dark-20: rgba(0,0,0,0.2);
  --color-dark-10: rgba(0,0,0,0.1);
  --color-dark-5: rgba(0,0,0,0.05);

  --color-light-100: rgba(255, 255, 255, 1);
  --color-light-90: rgba(255, 255, 255, 0.9);
  --color-light-80: rgba(255, 255, 255, 0.8);
  --color-light-70: rgba(255, 255, 255, 0.7);

  --color-dark-shadow: rgba(0, 0, 0, 0.8);
  --color-medium-shadow: rgba(0, 0, 0, 0.5);
  --color-light-shadow: rgba(0, 0, 0, 0.2);

  --color-font-light: rgba(255, 255, 255, 0.8);
  --color-font-medium: rgba(125, 125, 125, 1);
  --color-font-dark: rgba(0, 0, 0, 0.8);   

  font-size: 18px; 
}

body {
  margin: 0;  
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input, button {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

/***********************Buttons*/
button {
  height:36px;
  font-weight: 300;
  font-size: 1.2rem;
  border-radius: 5px;
  border: 0px;
  box-shadow: 0px 2px 3px 0px var(--color-light-shadow);
}

button.light {
  background-color:var(--color-dark-20); 
  color:var(--color-font-medium); 
}

button.light:hover {
  background-color:var(--color-dark-30); 
}

button.dark {
  background-color:var(--color-blue-1); 
  color:var(--color-font-light); 
}

button.dark:hover {
  background-color:var(--color-dark-50); 
}

/***********************Input*/
input {
  height:30px;
  border:1px solid var(--color-dark-10); 
  background-color:var(--color-dark-10);
  padding:5px;
  font-family: 'Poppins';
}

select {
  height:42px;
  border:1px solid var(--color-dark-10); 
  background-color:var(--color-dark-10);
  padding:5px;
  font-family: 'Poppins';
}

table {
  border-spacing: 0px;
  /*box-shadow: 0px 0px 3px 3px var(--color-dark-10);*/
  padding: 0px 10px 0px 5px;
}

table th, td {
  border-bottom: 1px solid var(--color-dark-10);
  margin: 0px;
}

table th {
  text-align: left;
  padding: 5px 10px 5px 10px;
  /*background-color: var(--color-blue-3);*/
  background-color: var(--color-light-100);
  font-size: 0.8rem;
  color: var(--color-font-medium);
  font-weight: 300;
  align-items: center;
  height: 50px;
  width: auto;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
}

table td {
  background-color: var(--color-light-100);
  margin: 0px;
  font-size: 0.9rem;
  padding: 5px;
  min-width: 120px;
}

/***********************Labels*/
label {
  font-size: 0.8rem;
}
/***********************Alerts*/
.alert {
  width: 70%;
  max-width: 500px;
  padding: 20px;
  margin: auto;
  z-index: 100;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 2px var(--color-medium-shadow);
  position: absolute;
  background-color: var(--color-light-100); 
  /*left: calc(50% - 270px);*/
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.alert.modal {
  text-align: center;
}

/************************Generics*/
.title {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--color-blue-1);
}